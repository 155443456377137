.Avatar {
  --color-user: var(--color-primary);
  --radius: 50%;

  flex: none;
  align-items: center;
  justify-content: center;
  width: 3.375rem;
  height: 3.375rem;
  border-radius: var(--radius);
  color: white;
  font-weight: bold;
  display: flex;
  white-space: nowrap;
  user-select: none;
  position: relative;

  > .inner {
    overflow: hidden;
    border-radius: var(--radius);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    z-index: 1;
    align-items: center;
    justify-content: center;
    background-image: linear-gradient(var(--color-white) -125%, var(--color-user));
  }

  &__media {
    width: 100%;
    height: 100%;
  }

  .emoji {
    width: 1rem;
    height: 1rem;
  }

  &__icon {
    font-size: 1.25em;
  }

  &.size-micro {
    width: 1rem;
    height: 1rem;
    font-size: 0.5rem;

    .emoji {
      width: 0.5625rem;
      height: 0.5625rem;
    }
  }

  &.size-tiny {
    width: 2rem;
    height: 2rem;
    font-size: 0.875rem;

    .emoji {
      width: 0.875rem;
      height: 0.875rem;
    }
  }

  &.size-mini {
    width: 1.5rem;
    height: 1.5rem;
    font-size: 0.75rem;

    .emoji {
      width: 0.75rem;
      height: 0.75rem;
    }
  }

  &.size-small {
    width: 2.125rem;
    height: 2.125rem;
    font-size: 0.875rem;

    .emoji {
      width: 0.875rem;
      height: 0.875rem;
    }
  }

  &.size-small-mobile {
    width: 2.5rem;
    height: 2.5rem;
    font-size: 0.875rem;

    .emoji {
      width: 0.875rem;
      height: 0.875rem;
    }
  }

  &.size-medium {
    width: 2.75rem;
    height: 2.75rem;
    font-size: 1.1875rem;

    .emoji {
      width: 1rem;
      height: 1rem;
    }
  }

  &.size-large {
    font-size: 1.3125rem;

    .emoji {
      width: 1.3125rem;
      height: 1.3125rem;
    }
  }

  &.size-giant {
    width: 5rem;
    height: 5rem;
    font-size: 2.5rem;

    .emoji {
      width: 2.5rem;
      height: 2.5rem;
    }
  }

  &.size-jumbo {
    width: 7.5rem;
    height: 7.5rem;
    font-size: 3.5rem;

    .emoji {
      width: 3.5rem;
      height: 3.5rem;
    }
  }

  &.interactive {
    cursor: var(--custom-cursor, pointer);
  }

  &.with-story-circle {
    z-index: 1;

    > .inner {
      width: calc(100% - 0.375rem);
      height: calc(100% - 0.375rem);
      left: 0.1875rem;
      top: 0.1875rem;
    }
  }

  &.with-story-solid {
    width: 3rem;
    height: 3rem;
    margin: 0.1875rem;

    &::before {
      content: "";
      position: absolute;
      width: 3.5rem;
      height: 3.5rem;
      left: -0.25rem;
      top: -0.25rem;
      border-radius: 50%;
      background: var(--color-borders-read-story);
    }

    &::after {
      content: "";
      position: absolute;
      width: 3.25rem;
      height: 3.25rem;
      left: -0.125rem;
      top: -0.125rem;
      border-radius: 50%;
      z-index: 0;
      background: var(--color-background);
    }

    &.size-tiny {
      width: 1.75rem;
      height: 1.75rem;

      &::before {
        width: 2.25rem;
        height: 2.25rem;
      }

      &::after {
        width: 2rem;
        height: 2rem;
      }
    }

    &.size-medium {
      width: 2.5rem;
      height: 2.5rem;

      &::before {
        width: 3rem;
        height: 3rem;
      }

      &::after {
        width: 2.75rem;
        height: 2.75rem;
      }
    }

    &.size-small-mobile {
      width: 2.25rem;
      height: 2.25rem;

      &::before {
        width: 2.75rem;
        height: 2.75rem;
      }

      &::after {
        width: 2.5rem;
        height: 2.5rem;
      }
    }

    &.online::after {
      bottom: -0.125rem;
      right: -0.125rem;
    }
  }

  &.has-unread-story::before {
    background-image: linear-gradient(215.87deg, var(--color-avatar-story-unread-from) -1.61%, var(--color-avatar-story-unread-to) 97.44%);
  }

  &.has-unread-story.close-friend::before {
    background-image: linear-gradient(215.87deg, var(--color-avatar-story-friend-unread-from) -1.61%, var(--color-avatar-story-friend-unread-to) 97.44%);
  }

  .poster {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
  }

  &.forum {
    --radius: var(--border-radius-forum-avatar);
  }

  &.hidden-user {
    --color-user: var(--color-deleted-account);
  }
}
